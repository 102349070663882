<template>
  <div class="searchresultboxmobail">
    <span class="d-flex justify-content-end recommendedmobail">
      <!-- <small class="tagrecommended px-2">{{$t("sabre.search-result.box.recommended")}}</small> -->
      <small class="tagrecommended px-2" v-if="isFcSupplied">{{$t("sabre.search-result.box.recommended")}}</small>
    </span>

    <mobile-one-flight-item :data="data.legs[0]" :fcFlight="isFcSupplied"/>
    <mobile-one-flight-item :data="data.legs[1]" :isBack="true" :fcFlight="isFcSupplied"/>

    <mobile-one-price-item :isRoundTrip="true" :totalPrice="totalPrice" :data="data"/>

</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'mobile-result-box-round-trip',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    MobileOneFlightItem: () => import('./mobileBoxAtom/mobileOneFlightItem'),
    MobileOnePriceItem: () => import('./mobileBoxAtom/mobileOnePriceItem'),
  },
  computed: {
    ...mapGetters({
      itinGroups: 'GET_SABRE_FLIGHT_ITIN_GROUPS',
    }),
    isFcSupplied() {
      const { itinGroups, data } = this;
      return itinGroups?.find((itin) => itin.id === data.itinGroupRef)?.fcSupplied;
    },
    totalPrice() {
      const { data } = this;
      return data.excerpt?.totalPrice || 0;
    },
  },
};
</script>
